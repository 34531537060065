<template>
  <div class="content order-con">
    <div class="m-card">
      <Tabs v-model="showTabs" @on-click="switchBrand">
        <TabPane :label="'名片夹（' + count.okAuth + '）'" name="1">
          <cardcase v-if="showTabs == '1'"></cardcase>
        </TabPane>
        <TabPane :label="'未回应（' + count.ingAuth + '）'" name="2">
          <notRespond v-if="showTabs == '2'"></notRespond>
        </TabPane>
      </Tabs>
    </div>
  </div>
</template>

<script>
import cardcase from "./components/order/cardcase";
import notRespond from "./components/order/not-respond";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "my-order",
  data() {
    return {
      showTabs: "1",
      actTabs: "1",
      count: {
        okAuth: 0,
        ingAuth: 0,
      },
    };
  },
  components: {
    cardcase,
    notRespond,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser",
    }),
  },
  created() {
    this.getCountWarp();
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    getCountWarp() {
      this.getCount("okAuth");
      this.getCount("ingAuth");
    },
    async getCount(type) {
      let query = `
        query($query:QueryInput!){
          exhibitorAppointmentQuery{
            query(query:$query){
              totalCount
            }
          }
        }
      `;
      let where = {
        Equal: {
          n: "memberId",
          v: this.user.inMember.memberId,
        },
        EqualA: {
          n: "exhibitionId",
          v: this.exhibitionId,
        },
      };
      if (type == "okAuth") {
        where.EqualB = { n: "state", v: 1 }; // 已回应
      } else if (type == "ingAuth") {
        where.NotEqualB = { n: "state", v: 1 };
      }
      let opt = {
        query: query,
        variables: {
          query: {
            page: 10000,
            size: 1,
            where: JSON.stringify(where),
          },
        },
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(JSON.stringify(data.data.exhibitorAppointmentQuery.query));
      this.count[type] = res.totalCount;
    },
    switchBrand(name) {
      if (this.actTabs != this.showTabs) {
        this.actTabs = this.showTabs;
        this.getCountWarp();
      }
    },
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.content {
  min-height: 700px;
  &.order-con {
    overflow: hidden;
  }
  .m-card {
    min-height: 600px;
    margin-bottom: 50px;
    border: solid 1px #ededed;
    .mc-top-title::before {
      opacity: 0;
      font-size: 16px;
    }
    ::v-deep .ivu-tabs {
      overflow: initial;
    }
  }
}
</style>
