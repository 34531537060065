<template>
  <div>
    <Modal
      :footer-hide="true"
      :mask-closable="false"
      @on-cancel="cancel"
      class-name="vertical-center-modal"
      v-model="show"
      width="640"
    >
      <div
        class="Modal-head"
        slot="header"
      >
        <div class="Modal-head-left"></div>
        <div class="Modal-head-right">预约</div>
      </div>
      <div class="Modal-center">
        <div
          class="Modal-item"
          style="margin-bottom: 30px"
        >
          <span>预约：</span>
          <div
            :class="activeIndex == index ? 'activeNames' : ''"
            :key="index"
            @click="
              activeIndex = index;
              activeType = item;
            "
            class="btn"
            v-for="(item, index) in categoryList"
          >{{ item }}</div>
        </div>
        <!-- <div class="Modal-item" style="margin-bottom: 30px">
          <span>预约日期：</span>
          <DatePicker
            type="date"
            placeholder="请选择预约日期"
            style="width: 500px"
          ></DatePicker>
        </div>
        <div class="Modal-item" style="margin-bottom: 30px">
          <span>预约时间：</span>
          <TimePicker
            type="time"
            placeholder="请选择预约时间"
            style="width: 500px"
          ></TimePicker>
        </div>-->
        <div
          class="Modal-item"
          style="margin-bottom: 30px"
        >
          <span>内容：</span>
          <Input
            :autosize="{ maxRows: 4, minRows: 4 }"
            :placeholder="`请详细描述您需要${ categoryList[activeIndex] || '见面'}的产品`"
            :rows="4"
            class="text_area"
            style="width: 500px; padding: 0;"
            type="textarea"
            v-model="con"
          />
        </div>
        <div style="margin: 0 auto; display: flex; justify-content: center">
          <Button
            @click="cancel"
            style="width: 130px; height: 36px; margin-right: 20px"
          >取消</Button>
          <Button
            @click="submit"
            style="width: 130px; height: 36px"
            type="primary"
          >提交</Button>
        </div>
      </div>
    </Modal>
    <Modal
      :footer-hide="true"
      :mask-closable="false"
      @on-cancel="hintShow = false"
      class-name="vertical-center-modal"
      v-model="hintShow"
      width="640"
    >
      <p>您的预约信息已成功发送</p>
      <p>后续动态请在个人中心预约中查询</p>
      <div style="margin: 0 auto; display: flex; justify-content: center">
        <Button
          @click="hintShow = false"
          style="width: 130px; height: 36px; margin-right: 20px"
        >取消</Button>
        <Button
          @click="goToLook"
          style="width: 130px; height: 36px"
          type="primary"
        >去看看</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "orderModal",
  props: {
    exhibitorId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      categoryList: ["见面", "采购", "代理", "其他"],
      activeIndex: -1,
      activeType: "",
      show: false,
      con: "",
      editInfo: {},
      isEdit: false,
      hintShow: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      user: "getUser"
    }),
  },
  created () { },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
      addOrder: "exhibitor/addOrder",
      editOrder: 'user/editOrder',
      getUserAdvisorySetting:"roleModule/getUserAdvisorySetting"
    }),
    open (info = null) {
      this.show = true;
      if (info) {
        this.isEdit = true;
        this.editInfo = info;
        this.categoryList.forEach((e, i) => {
          if (e == info.type) {
            this.activeIndex = i;
            this.activeType = e;
          }
        });
        this.con = info.content;
      }
    },
    async submit () {
      if (!this.exhibitorId && !this.isEdit) {
        this.$Message.warning("展商信息有误！");
        return;
      }
      if (!this.activeType) {
        this.$Message.warning(`请选择预约类型！`);
        return;
      }
      if (!this.con) {
        this.$Message.warning("请输入内容！");
        return;
      }
      if (this.isEdit) {
        let body = {
          id: this.editInfo.id,
          opt: {
            type: this.activeType,
            content: this.con,
            auditState: 0
          }
        }
        let result = await this.editOrder(body);
        if (result.result) {
          this.$Message.success('修改成功');
          this.$emit('finish')
          this.cancel();
        } else {
          this.$Message.error(result.message || '修改失败');
        }
        //编辑模式
      } else {
        // 新增模式
        let body = {
          exhibitionId: this.exhibitionId,
          exhibitorId: this.exhibitorId,
          memberId: this.user.id,
          type: this.activeType,
          content: this.con,
        };
        let result = await this.addOrder(body);
        if (result.result) {
          this.show = false;
          this.hintShow = true;
        } else {
          this.$Message.error(result.message || '发送失败');
        }
        this.getUserAdvisorySetting()
        this.cancel();
      }
    },
    cancel () {
      this.show = false;
      this.check = "见面";
      this.isEdit = false;
      this.con = "";
      this.info = {};
      this.activeIndex = -1;
      this.activeType = "";
    },
    goToLook () {
      this.$router.push({ path: '/user/order' });
    }
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.Modal-head {
  display: flex;
  align-items: center;
  &-left {
    width: 4px;
    height: 18px;
    border: solid 3px #1890ff;
    @include border_color(#1890ff);
    margin-right: 10px;
  }
  &-right {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
}
.Modal-center {
  padding: 24px 18px;
}
.Modal-item {
  display: flex;
  align-items: center;
}
.btn {
  width: 117px;
  height: 36px;
  background-color: #f7f7f7;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  user-select: none;
  &:hover,
  &.activeNames {
    @include background_color(#1890ff);
    color: #fff;
  }
}
::v-deep {
  .ivu-modal-body {
    padding-left: 0;
    .text_area {
      textarea {
        resize: none;
      }
    }
    p {
      text-align: center;
      line-height: 24px;
      margin-bottom: 20px;
      &:first-child {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
  .vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;

    .ivu-modal {
      top: 0;
    }
  }
}
</style>
